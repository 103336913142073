#quote {
  padding-top: 3rem;
  background: rgb(53, 53, 53);
  padding: 6rem;
}
.expressionText {
  font-family: 'Oleo Script', cursive;

  font-size: 29px;
  color: rgb(195, 195, 195);
  text-align: center;
  font-weight: bold;
  margin: 252px auto;
  margin-bottom: 233px;
  padding-left: 30px;
  padding-right: 30px;
}
.lowerQuote {
  margin-top: 300px;
}
.continueBTN {
  width: 110px;

  background-color: rgb(243, 243, 243);
  text-align: center;
  color: rgb(110, 148, 89);
  border-radius: 15px;
  font-weight: bold;
  font-size: 18px;
}

.continueBTN:hover {
  background-color: rgb(255, 255, 255);
  font-size: 20px;
}
.arrowDown {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .expressionText {
    margin: 14em auto 0;
    width: 43%;
  }
  .arrowDown {
    text-align: center;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .expressionText {
    margin: 14em auto 0;
    width: 51%;
  }
  .arrowDown {
    text-align: center;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .expressionText {
    margin: 2em auto 0;
    width: 100%;
    display: contents;
  }
  .arrowDown {
    text-align: center;
    margin-top: 50%;
  }
}
@media only screen and (max-width: 320px) {
  .expressionText {
    margin: 2em auto 0;
    width: 96%;
  }
  .arrowDown {
    text-align: center;
    margin-top: 30px;
  }
}
