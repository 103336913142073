.portfolioBTN {
  font-weight: bold;
}
#portfolio {
  padding-top: 10%;
}
.portfolioSection {
  padding-left: 10%;
}
.appButtons {
  font-family: 'Fugaz One', cursive;
  font-weight: bold;
  transform: translateX(100rem);
  animation: slideIn 0.9s forwards;
}
.appButtons:hover {
  font-size: 20px;
}

.nagleParking {
  background-image: url('./Img/NagleParking.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgb(233, 233, 233);
  box-shadow: 0 1px 3px 5px currentColor;

  /* transform: translateX(100rem);
  animation: slideIn 0.9s forwards; */
}
.triviaGame:hover {
  border: 3px solid rgb(110, 148, 89);
}
.jeopardy {
  background-image: url('./Img/Jeopardy.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgb(233, 233, 233);
  box-shadow: 0 1px 3px 5px currentColor;
  /* transform: translateX(100rem);
  animation: slideIn 0.9s forwards; */
}
.jeopardy:hover {
  border: 3px solid rgb(110, 148, 89);
}
.toDoList {
  background-image: url('./Img/To_Do_List.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 1px 3px 5px currentColor;

  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgb(233, 233, 233);

  /* transform: translateX(100rem);
  animation: slideIn 0.9s forwards; */
}
.toDoList:hover {
  border: 3px solid rgb(110, 148, 89);
}
.movieInfo {
  background-image: url('./Img/Movie_Info_App.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgb(233, 233, 233);
  box-shadow: 0 1px 3px 5px currentColor;

  /* transform: translateX(100rem);
  animation: slideIn 0.9s forwards; */
}
.movieInfo:hover {
  border: 3px solid rgb(110, 148, 89);
}
.groceries {
  background-image: url('./Img/Groceries_With_React.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgb(233, 233, 233);
  box-shadow: 0 1px 3px 5px currentColor;

  /* transform: translateX(100rem);
  animation: slideIn 0.9s forwards; */
}
.groceries:hover {
  border: 3px solid rgb(110, 148, 89);
}
.checkTheNews {
  background-image: url('./Img/Check_The_News.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgb(233, 233, 233);
  box-shadow: 0 1px 3px 5px currentColor;

  /* transform: translateX(100rem);
  animation: slideIn 0.9s forwards; */
}
.checkTheNews:hover {
  border: 3px solid rgb(110, 148, 89);
}

.btnToView {
  font-family: 'Oleo Script', cursive;
  width: 250px;
  background-color: rgb(110, 148, 89);
  text-align: center;
  color: white;
  margin-top: 30px;
  border-radius: 15px;
  font-weight: bold;
  transform: translateX(100rem);
  animation: slideIn 0.9s forwards;
}
.btnToView:hover {
  background-color: rgb(246, 245, 245);
}
.card {
  width: 100%;
}
.card-text {
  text-align: center;
  font-weight: bold;

  color: rgb(110, 148, 89);
}

.firstTextRow {
  padding: 3rem 1rem 0 0;
  /* transform: translateX(100rem);
  animation: slideIn 0.9s forwards; */
}
.secondTextRow {
  padding: 2rem 1rem 0 0;

  /* transform: translateX(100rem);
  animation: slideIn 0.9s forwards; */
}

.secondCol {
  box-shadow: 0px 2px 51px 0px rgba(0, 0, 0, 0.2);

  border-top: 3px solid rgb(241, 241, 241);
  border-bottom: 3px solid rgb(241, 241, 241);
  padding-top: 80px;
  padding-bottom: 100px;
  padding-right: 15px;
  padding-left: 15px;
}

.footerArrow {
  color: grey;
  font-weight: bold;
  opacity: 0.6;
  margin: 10% auto;
  font-size: 30px;
  padding-right: 15%;
}
.col1Text {
  font-family: 'Fugaz One', cursive;
  margin-top: 30%;
  text-align: left;
  color: rgb(126, 144, 25);
  transform: translateX(100rem);
  animation: slideIn 0.9s forwards;
}
.col1secondText {
  font-family: 'Oleo Script', cursive;
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
  color: grey;
}

@media only screen and (max-width: 991px) {
  .secondCol {
    margin: 14em auto 0;
    width: 51%;
  }
  .portfolioSection {
    margin: 0 21%;
  }

  .footerArrow {
    display: none;

    /* color: grey;
    font-weight: bold;
    opacity: 0.6;
    margin: 10% auto;
    font-size: 30px;
    padding-right: 15%; */
  }
}

@media only screen and (max-width: 640px) {
  .secondCol {
    margin: 14em auto 0;
    width: 51%;
  }
  .portfolioSection {
    margin: 0 21%;
  }

  .col1Text {
    margin-top: 0;
    text-align: left;
    color: rgb(126, 144, 25);
  }
  .innerText1 {
    color: gainsboro;
  }
  .footerArrow {
    display: none;

    /* color: grey;
    font-weight: bold;
    opacity: 0.6;
    margin: 10% auto;
    font-size: 30px;
    padding-right: 15%; */
  }
}

@media only screen and (max-width: 480px) {
  .secondCol {
    margin: 2em auto 0;
    width: 100%;
  }

  .portfolioSection {
    margin: 0 auto 21%;
    padding-left: 15%;
  }
  .innerText1 {
    color: gainsboro;
  }
  .col1Text {
    margin-top: 0;

    text-align: left;
    color: rgb(126, 144, 25);
  }
  .footerArrow {
    display: none;
    /* color: grey;
    font-weight: bold;
    opacity: 0.6;
    margin: 10% auto;
    font-size: 30px;
    padding-right: 15%; */
  }
}
@media only screen and (max-width: 320px) {
  .secondCol {
    margin: 2em auto 0;
    width: 96%;
  }
  .innerText1 {
    color: gainsboro;
  }

  .col1Text {
    margin-top: 0;

    text-align: left;
    color: rgb(126, 144, 25);
  }
  .portfolioSection {
    margin: 0 21%;
  }
  .footerArrow {
    display: none;

    /* color: grey;
    font-weight: bold;
    opacity: 0.6;
    margin: 10% auto;
    font-size: 30px;
    padding-right: 15%; */
  }
}
