.backgroundImg {
  background-image: url('./Image/portfolio_Background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: solid rgb(233, 233, 233);
  box-shadow: 0px 2px 600px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
}
.nameText {
  font-weight: bold;
  color: rgb(110, 148, 89);
  font-size: 30px;
}

.btnContinue {
  font-family: 'Oleo Script', cursive;
  width: 250px;
  background-color: rgb(110, 148, 89);
  text-align: center;
  color: rgb(255, 255, 255);
  margin-top: 20px;

  border-radius: 15px;
  font-weight: bold;
}
.btnContinue:hover {
  background-color: rgb(235, 235, 235);
}

.innerFont1 {
  font-family: 'Oleo Script', cursive;
  font-size: 27px;
  font-weight: bold;
  padding: 9px;
  margin-top: 45px;
  color: rgb(64, 107, 79);
}
.innerFont2 {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.innerText1 {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
.innerText2 {
  margin-top: 20px;
  text-align: center;
}
.intoText {
  color: green;
  padding-left: 5px;
  padding-right: 5px;

  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.backgroundText {
  font-weight: bold;
  padding-top: 206px;
  margin: 0 auto;
}
@media only screen and (max-width: 1860px) {
  .btnContinue {
    font-family: 'Oleo Script', cursive;
    width: 250px;
    background-color: rgb(110, 148, 89);
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 10px;
    margin-left: 2%;

    border-radius: 15px;
    font-weight: bold;
  }
  .backgroundText {
    font-weight: bold;
    padding-top: 156px;
    margin: 0 auto;
  }
  .innerFont1 {
    font-family: 'Oleo Script', cursive;
    font-size: 27px;
    font-weight: bold;
    padding: 10px;
    margin-left: 36px;
    margin-top: 9%;
    color: rgb(64, 107, 79);
  }
}
@media only screen and (max-width: 940px) {
  .btnContinue {
    font-family: 'Oleo Script', cursive;
    width: 250px;
    background-color: rgb(110, 148, 89);
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 20px;
    margin-left: 2%;

    border-radius: 15px;
    font-weight: bold;
  }
  .backgroundText {
    font-weight: bold;
    padding-top: 233px;
    margin: 0 auto;
  }
  .innerFont1 {
    font-family: 'Oleo Script', cursive;
    font-size: 27px;
    font-weight: bold;
    padding: 10px;
    margin-left: 36px;
    margin-top: 6%;
    color: rgb(64, 107, 79);
  }
}
@media only screen and (max-width: 640px) {
  .btnContinue {
    font-family: 'Oleo Script', cursive;
    width: 250px;
    background-color: rgb(110, 148, 89);
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 33px;
    margin-left: 18%;
    border-radius: 15px;
    font-weight: bold;
  }
  .backgroundText {
    font-weight: bold;

    margin: 0 auto;
  }
  .innerFont1 {
    font-family: 'Oleo Script', cursive;
    font-size: 27px;
    font-weight: bold;
    padding: 10px;
    margin-left: 20px;
    margin-top: -60px;

    color: rgb(64, 107, 79);
  }
}

@media only screen and (max-width: 480px) {
  .btnContinue {
    font-family: 'Oleo Script', cursive;
    width: 250px;
    background-color: rgb(110, 148, 89);
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 33px;
    margin-left: 18%;

    border-radius: 15px;
    font-weight: bold;
  }
  .backgroundText {
    font-weight: bold;
    margin: 0 auto;
  }
  .innerFont1 {
    font-family: 'Oleo Script', cursive;
    font-size: 27px;
    font-weight: bold;
    padding: 10px;
    margin-left: 20px;

    color: rgb(64, 107, 79);
  }
}
@media only screen and (max-width: 320px) {
  .btnContinue {
    font-family: 'Oleo Script', cursive;
    width: 250px;
    background-color: rgb(110, 148, 89);
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 20px;
    margin-left: 20px;

    border-radius: 15px;
    font-weight: bold;
  }
  .backgroundText {
    font-weight: bold;
    padding-top: 156px;
    margin: 0 auto;
  }
  .innerFont1 {
    font-family: 'Oleo Script', cursive;
    font-size: 27px;
    font-weight: bold;
    padding: 10px;
    margin-left: 20px;
    margin-top: 30%;

    color: rgb(64, 107, 79);
  }
}
