.nav {
  align-items: center;
  text-transform: uppercase;
  font-size: 1.6rem;
}
.nameText {
  padding-left: 30px;
  font-family: 'Fugaz One', cursive;
}
.nameText:hover {
  color: white;
}

.navbar-brand span {
  color: rgb(110, 148, 89);
}
nav ul li {
  list-style: none;
  transform: translateX(100rem);
  animation: slideIn 0.5s forwards;
}
nav ul li:nth-child(1) {
  animation-delay: 0s;
}

nav ul li:nth-child(2) {
  animation-delay: 0.5s;
}

nav ul li:nth-child(3) {
  animation-delay: 1s;
}

nav ul li:nth-child(4) {
  animation-delay: 1.5s;
}

nav ul li a {
  margin: 0 2rem;
  position: relative;
  letter-spacing: 2px;
}

nav ul li a:last-child {
  margin-right: 0;
}

nav ul li a::before,
nav ul li a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: rgb(110, 148, 89);
  left: 0;
  transform: scaleX(0);
  transition: all 0.5s;
}

nav ul li a::before {
  top: 0;
  transform-origin: left;
}

nav ul li a::after {
  bottom: 0;
  transform-origin: right;
}

nav ul li a:hover::before,
nav ul li a:hover::after {
  transform: scaleX(1);
}
.navBtns {
  font-family: 'Fugaz One', cursive;
  margin-left: 75%;
}

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* The sidebar links */
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* The button used to open the sidebar */
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s; /* If you want a transition effect */
  padding: 20px;
}
@keyframes slideIn {
  from {
  }
  to {
    transform: translateX(0);
  }
}

@media screen and (max-width: 700px) {
  .nav-bar-margin {
    margin-left: 0px;
  }
  .navbar-toggler-icon {
    margin-left: 0px;
  }
  .logo-brand {
    margin-left: 0px;
  }
}
@media screen and (max-width: 600px) {
  .nav-bar-margin {
    margin-left: 0px;
  }
  .navbar-toggler-icon {
    margin-left: 0px;
  }
  .logo-brand {
    margin-left: 0px;
  }
  .navbar-toggler {
    padding: 0.25rem 9.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: contents;
  }
  nav ul li a:last-child {
    margin-left: 0;
    margin-right: 0;
  }
  .nameText {
    padding-left: 0;
  }
  .navBtns {
    padding-left: 1rem;

    margin: 0;
    display: flex;
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 480px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
  .navbar-toggler {
    padding: 0.25rem 9.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: contents;
  }
  nav ul li a:last-child {
    margin-right: 0;
  }
  .nav ul li a {
    margin: 0;
  }
  .nameText {
    padding-left: 0;
  }
  .navBtns {
    padding-left: 10px;

    margin: 0;
    display: flex;
  }
}
